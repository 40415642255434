import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Naviguer chez Avis Car Rental
			</title>
			<meta name={"description"} content={"Le mystère de la conduite de luxe - Les réponses à vos questions\n"} />
			<meta property={"og:title"} content={"FAQ | Naviguer chez Avis Car Rental"} />
			<meta property={"og:description"} content={"Le mystère de la conduite de luxe - Les réponses à vos questions\n"} />
			<meta property={"og:image"} content={"https://bordeauxblis.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bordeauxblis.com/media/3453452236623.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bordeauxblis.com/media/3453452236623.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 30px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
				Vous êtes curieux de connaître les tenants et les aboutissants de l'expérience du luxe sur roues ? Vous êtes au bon endroit. Notre section Foire aux questions est là pour vous apporter toutes les réponses dont vous avez besoin. Du processus de location d'une voiture de rêve aux détails essentiels sur notre flotte, considérez cette section comme votre porte d'entrée pour percer les mystères et l'excitation d'Avis. Plongez dans nos FAQ et embarquez pour un voyage où les questions rencontrent l'élégance des réponses, pour que votre voyage avec nous soit aussi facile qu'exaltant.
	Foire aux questions :
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Qu'est-ce qui rend les voitures Avis uniques ?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Notre flotte est composée de voitures de luxe et de voitures exotiques soigneusement sélectionnées, réputées pour leurs performances, leur style et leur technologie de pointe. Chaque véhicule est un chef-d'œuvre de l'ingénierie automobile, promettant une expérience de conduite inégalée.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Comment puis-je louer une voiture chez Avis ?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Louer une voiture est un jeu d'enfant. Il vous suffit de prendre contact avec notre équipe et nous vous guiderons tout au long du processus, en veillant à ce que vous trouviez le véhicule parfait pour vos besoins et vos préférences.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Y a-t-il des restrictions d'âge pour louer une voiture de luxe?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Pour louer une voiture chez Avis, vous devez être âgé d'au moins 25 ans, avoir un permis de conduire valide et une assurance. Notre engagement en matière de sécurité et de responsabilité garantit une expérience de conduite sûre et agréable.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Quelles sont les mesures à prendre en compte lors du choix d'un véhicule de prêt ?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Compte tenu de la nature exclusive de nos voitures de luxe et exotiques, nous vous recommandons de tenir compte de votre expérience de conduite, de vos préférences et de votre aisance avec les véhicules à hautes performances. Notre équipe se fera un plaisir de vous aider à choisir une voiture qui corresponde à vos attentes.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Que faire si j'ai un modèle de voiture particulier en tête ?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Si vous avez un modèle particulier de voiture de luxe ou de voiture exotique à l'esprit, faites-le nous savoir au cours de la procédure de réservation. Bien que nous nous efforcions de répondre à vos préférences, la disponibilité peut varier.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							Y a-t-il des frais supplémentaires en plus du coût de la location ?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Nos prix sont transparents et couvrent le coût de la location et l'assurance standard. Des frais supplémentaires peuvent s'appliquer pour les options et les services, que notre équipe vous communiquera clairement avant de finaliser votre réservation.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://bordeauxblis.com/media/7.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Pour toute question ou pour vous plonger dans le monde de la conduite de luxe, contactez Avis. Notre équipe s'engage à vous fournir des réponses et des conseils pour que votre expérience de conduite soit tout simplement exceptionnelle.
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://bordeauxblis.com/media/4.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});